/* Navbar styles */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 1rem;
  padding-bottom: 2rem;
  display:  flex-wrap; /* Allow the navbar to wrap around content */
}

.navbar-left {
  display: flex;
  align-items: center;
  flex-grow: 1; /* Allows the logo to take up remaining space */
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-right.active {
  flex-direction: column;
  align-items: flex-end;
}

.logo{
  display: flex;
  align-items: center;
}

.logo img{
  width: 100px;
  height: auto;
  margin-top: -1rem;
  margin-bottom: -2rem;
}

.nav-links {
  list-style: none;
  display: flex;
}

.nav-links li {
  margin-left: 1rem;
}

.nav-links li a {
  text-decoration: none;
  color: var(--color-primary);
  padding: 10px 20px;
  background: var(--color-secondary);
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out; /* Add transition properties */
}

.nav-links li a:hover {
  color: var(--color-secondary);
  background: var(--color-primary);
}

.menu-icon {
  display: none; /* Initially hide the menu icon */
  cursor: pointer;
}

.menu-icon span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #080808;
  margin-bottom: 5px;
  transition: transform 0.3s ease-in-out;
}

@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: row; /* Stack items vertically on smaller screens */
    padding: 0.5rem;
    }

  .navbar-left,
  .navbar-right {
    width: 100%; /* Make each column take up full width */
    text-align: center; /* Center content within columns */
  }
  
  .navbar-right {
    flex-basis: 50%; /* Each column takes up 50% width on smaller screens */
    text-align: right; /* Align content to the right */
    display: flex;
    justify-content: flex-end; /* Align items to the end (right side) */
    align-items: center; /* Align items vertically */
  }

  .logo {
    margin-bottom: 1rem;
  }

  .logo img {
    width: 80px; /* Adjust the width as desired */
  }

  .menu-icon {
    display: block; /* Show the menu icon on smaller screens */
    margin-right: 0.5rem; /* Add some spacing between the icon and the content */
  }

  .menu-icon span {
    width: 20px; /* Adjust the menu icon size for smaller screens */
  }

  .nav-links {
    flex-direction: column;
    align-items: center;
    display: none; /* Initially hide the navigation links */
    transition: all 0.3s ease; /* Add a smooth transition effect */
  }

  .nav-links.show {
    display: flex; /* Show the navigation links when menu is clicked */
  }

  .nav-links li {
    margin: 0.5rem 0;
  }

  .nav-links li a {
    padding: 6px 12px;
    font-size: 12px; 
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .logo img {
    width: 60px; /* Adjust the logo size for wider screens */
  }

  .menu-icon span {
    width: 25px; /* Adjust the menu icon size for wider screens */
  }
}
