/* Bestseller styles */
.bestseller {
  background-color: #fff;
  padding: 1rem;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.product-item {
  flex: 0 0 calc(20% - 20px);
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.product-image {
  width: 100%;
  max-width: 200px;
  height: 200px;
  border: 5px solid var(--color-primary);
  padding: .5rem;
  box-sizing: border-box;
}

.product-image img {
  width: 20rem;
  height: 13rem;
  object-fit: cover;
}

.product-name {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .product-item {
    width: 100%; 
  }

  .product-image {
    max-width: 150px; 
    padding: 0.25rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .product-image {
    padding: 0.25rem;
  }
}
