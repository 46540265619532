/* Header styles */
.header {
  text-align: center;
  padding: 40px;
  opacity: 0; /* Initially set opacity to 0 */
  animation: fadeIn 1s forwards; /* Apply fade-in animation */
}

.header h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
}

.header p {
  font-size: 18px;
  margin-bottom: 20px;
}

.header .button{
  color: var(--color-primary);
  padding: 10px 20px;
  background: var(--color-secondary);
  border: none;
  border-radius: 4px;
  font-size: 17px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
}

.header .button:hover {
  color: var(--color-secondary);
  background: var(--color-primary);
}

@keyframes fadeIn {
  from {
    opacity: 0; /* Start with opacity 0 */
  }
  to {
    opacity: 1; /* End with opacity 1 */
  }
}

@media (max-width: 768px) {
  .header {
    padding: 20px;
  }

  .header h1 {
    font-size: 28px;
    margin-bottom: 0.5rem;
  }

  .header p {
    font-size: 16px;
    margin-bottom: 1rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .header {
    padding: 30px; /* Increase padding for wider screens */
  }

  .header h1 {
    font-size: 40px; /* Slightly increase font size */
    margin-bottom: 1rem;
  }

  .header p {
    font-size: 18px; /* Slightly increase font size */
    margin-bottom: 1.5rem;
  }
}
