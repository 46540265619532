/* Newarrival styles */

.newarrival {
  padding: 1rem;
  margin: 0rem;
}

.product-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.product-image {
  width: 100%;
  max-width: 100%; 
  height: auto;
  border: 3px solid var(--color-primary);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);
}

.product-image img {
  width: 20.5rem;
  height: 13rem;
  padding: .05rem .05rem;
  object-fit: cover;
}

.product-name {
  margin-top: 10px;
}
.custom-slider .slick-prev,
.custom-slider .slick-next {
  width: 40px;
  height: 40px; 
  z-index: 1; 
  border-radius: 50%;
}

.custom-slider .slick-prev:before,
.custom-slider .slick-next:before {
  color: var(--color-primary);
  font-size: 20px; 
}

.custom-slider .slick-prev {
  left: 5px;
  top: 43%;
}

.custom-slider .slick-next {
  right: 5px;
  top: 43%; 
}

@media (max-width: 768px) {
  .product-item {
    width: 100%; /* Make product items take up full width on smaller screens */
  }

  .product-image {
    max-width: 150px; /* Adjust product image size for smaller screens */
    padding: 0.25rem;
    background-color: var(--color-background);
    border: 0px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .product-image {
    max-width: 180px; /* Adjust product image size for wider screens */
    padding: 0.25rem;
    background-color: var(--color-background);
    border: 0px;
  }
}

@media (max-width: 1450px) {
  .product-image {
    padding: 0.25rem;
  }
  .product-image img {
    width: 20rem;
    height: 10rem;
    object-fit: cover;
  }
}
