.about {
  margin: 0rem;
}

.about__content{
  display: flex;
  flex-direction: row;
  text-align: justify;
}

.about__heading,
.about__content div {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.about-visible .about__heading,
.about-visible .about__content div {
  opacity: 1;
  transform: translateY(0);
}

.logo {
  text-align: center;
  margin-bottom: 1rem;
}

.about .logo img {
  width: 600px;
  height: auto;
  margin-top: -1rem;
  margin-bottom: -2rem;
}

h1 {
  font-size: 2rem;
  text-align: center;
}

h3 {
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .about__content {
    flex-direction: column-reverse;
  }

  .about .logo img {
    display: None;
  }

  h1 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  h3 {
    font-size: 1.2rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .about__content {
    flex-direction: row; 
  }

  .about .logo img {
    max-width: 25rem; 
    padding-left: 60px;
    margin: -2rem;
    padding-top: -2rem;
  }

  h1 {
    font-size: 2rem; 
    margin-bottom: 0.75rem;
  }

  h3 {
    font-size: 1.3rem; 
    margin-top: 2rem;
    margin-bottom: 0.75rem;
  }
}