.feed {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto; 
  gap: 0.5rem;
  position: relative;
}

.feed__item {
  position: relative;
  width: 240px; 
  height: 240px;
  flex-shrink: 0;
  overflow: hidden;
}

.feed__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feed__item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.feed__item:hover .overlay {
  opacity: 1;
}

.see-more-text {
  color: white;
  text-align: center;
  cursor: pointer;
  font-size: 25px;
}

.see-more-mobile {
  width: 240px;
  height: 240px;
  flex-shrink: 0; 
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .feed__item {
    transform: scale(1.02); 
  }
}

/* @media screen and (max-width: 767px) {
  .feed {
    flex-wrap: wrap; 
    overflow-x: hidden;
    gap: 0.5rem;
    justify-content: center;
  }
  .feed__item {
    transform: scale(1); 
  }
} */

@media screen and (max-width: 767px) {
  .feed {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: calc(100% - 10px); 
    padding-bottom: 20px;
  }

  .feed__item {
    width: 240px;
    height: 240px;
  }
}