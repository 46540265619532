
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap');

:root {
  --font-base: 'Cormorant Upright', serif;
  --font-alt: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  
  --color-background:  #f4eddf;
  --color-primary: #1a1a1a;
  --color-secondary: #ec7c04;
  --color-accent: #312676;
  --color-text: #080808;
}

body {
  margin: 0;
  width: 100%;
  font-family: var(--font-alt);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #1a1a1a; 
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: #1a1a1a; 
}

h3 {
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  color: #1a1a1a; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
